<div class="logo">
  <img src="assets/c-logo.svg?v=1" class="logo-a" alt="logo" width="64" height="64">
</div>

<ng-container *ngIf="currentUser">
  <div #container class="items-container" [class.on-scrolling]="scrolling">
    <a class="item al-ico-home"
       routerLink="/dashboard"
       routerLinkActive="active"
       (click)="resetSearch()"
       smTooltip="DASHBOARD"
       [matTooltipShowDelay]="0"
       matTooltipPosition="right">
    </a>
    <a class="item al-ico-projects"
       smTooltip="PROJECTS"
       [matTooltipShowDelay]="0"
       matTooltipPosition="right"
       routerLink="/projects"
       routerLinkActive="active">
    </a>
    <a class="item al-ico-datasets"
       [routerLink]="(defaultNestedModeForFeature$ | async)?.['datasets']? '/datasets/simple/*/projects' : '/datasets'"
       [class.active]="(baseRouteConfig$ | async) ==='datasets'"
       smTooltip="DATASETS"
       [matTooltipShowDelay]="0"
       matTooltipPosition="right">
    </a>
    <a class="item al-ico-pipelines"
       [routerLink]="(defaultNestedModeForFeature$ | async)?.['pipelines']? '/pipelines/*/projects' : '/pipelines'"
       [class.active]="(baseRouteConfig$ | async) ==='pipelines'"
       smTooltip="PIPELINES"
       [matTooltipShowDelay]="0"
       matTooltipPosition="right">
    </a>
    <a class="item al-ico-reports"
       [routerLink]="(defaultNestedModeForFeature$ | async)?.['reports']? '/reports/*/projects' : '/reports'"
       [class.active]="(baseRouteConfig$ | async) ==='reports'"
       smTooltip="REPORTS"
       [matTooltipShowDelay]="0"
       matTooltipPosition="right">
    </a>
    <a class="item al-ico-queues"
       routerLink="/workers-and-queues"
       routerLinkActive="active"
       smTooltip="WORKERS & QUEUES"
       [matTooltipShowDelay]="0"
       matTooltipPosition="right">
    </a>
  </div>
</ng-container>

<div *ngIf="currentUser" class="account">
  <a *ngIf="environment.slackLink" class="item" [href]="environment.slackLink"
     target="_blank"
     smTooltip="Community support on Slack" [matTooltipShowDelay]="0" matTooltipPosition="right">
    <i class="i-slack-mark-color"></i>
  </a>
</div>
